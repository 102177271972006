export const resetDataLayer = () => import('./reset');
export const transactionEvent = () => import('./transaction');
export const viewItemListEvent = () => import('./ecommerce');
export const selectItemEvent = () => import('./ecommerce');
export const viewItemEvent = () => import('./ecommerce');
export const addToCartEvent = () => import('./ecommerce');
export const removeFromCartEvent = () => import('./ecommerce');
export const viewCartEvent = () => import('./ecommerce');
export const viewPromotionEvent = () => import('./ecommerce');
export const selectPromotionEvent = () => import('./ecommerce');
export const checkoutStepEvent = () => import('./ecommerce');
export const purchaseEvent = () => import('./ecommerce');
